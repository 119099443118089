<template>
  <div class="chat-item-container">
    <div
      class="live-chat-li"
      v-for="(item, index) in meetingInfo.chat_list"
      :key="index"
    >
      <div class="live-chat-name">
        <!-- <span v-for="(name) in item.name">{{ name }}</span> -->
        <span>{{item.name.slice(0,1)}}**</span>
      </div>
      <div>：</div>
      <div class="live-chat-record">
        <div class="live-chat-parse block"  v-if="item.content_type == 2">
          <span>点赞了会议</span>
          <img :src="require('@/assets/images/live/parse.png')" alt="">
        </div>
        <div class="live-chat-flower block" v-if="item.content_type == 3">
          <span>送出了一朵红花</span>
          <img :src="require('@/assets/images/live/flower.png')" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ItemScroll from "@/components/unit/ItemScroll";
export default {
  name: "chat-item",
  components: {
    ItemScroll,
  },
  props:{
    meetingInfo:{
      type:Object
    }
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.chat-item-container {
  padding:1rem 0;
}
.live-chat-li {
  display: flex;
  line-height: 1.6rem;
  font-size:0.9rem;
}
.live-chat-name {
  /* flex: 3.6rem 0 0; */
  /* word-break: keep-all; */
  padding-left: 0.5rem;
  display: flex;
  justify-content: space-between;
}
.live-chat-record {
  padding-right: 0.5rem;
}
.block{
  display: flex;
  align-items: center;
}
.block span{
  margin-right:.2rem;
}
.block img{
  width:1rem;
  height:auto;
}
</style>
